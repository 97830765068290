import React, { useState, useEffect, useCallback, createContext } from 'react';
import { http } from 'config/Api';
import { fetchUser } from 'services/users';
import { getUserAssets, getUserMeters } from 'services/users';

export const UserSessionContext = createContext();
let isRefreshing = false;

const UserSessionProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [userAssets, setUserAssets] = useState([]);
  const [userMeters, setUserMeters] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [tycAccepted, setTycAccepted] = useState();

  const callFetchUser = useCallback((token, refresh) => {
    if (token) {
      http.defaults.headers.common.Authorization = `Bearer ${token}`;
      http.defaults.headers.common['access-token'] = `Bearer ${token}`;
      localStorage.setItem('token', token);
    }
    if (refresh) {
      localStorage.setItem('refresh', refresh);
    }
    setIsLoggingIn(true);
    return fetchUser()
      .then((response) => {
        if (response.data.id) setUser(response.data);
        if (response.data.profile) setTycAccepted(response.data.profile.eula);
        setIsLoggingIn(false);
        return true;
      })
      .catch((error) => {
        if (
          error.response &&
          `${error.response.config.baseURL}/${error.response.config.url}` ===
            `${error.response.config.baseURL}/accounts/token/refresh/`
        ) {
          localStorage.removeItem('token');
          localStorage.removeItem('refresh');
          http.defaults.headers.common.Authorization = null;
          http.defaults.headers.common['access-token'] = null;
          setUser(undefined);
        }
        setIsLoggingIn(false);
      });
  }, []);

  const catchWithRefresh = useCallback((error) => {
    const originalRequest = error.config;

    if (
      !isRefreshing &&
      localStorage.getItem('refresh') &&
      error.response &&
      error.config &&
      !error.request.responseURL.includes('refresh') &&
      (error.response.status === 403 || error.response.status === 401) &&
      error.response.data.code === 'token_not_valid' &&
      !originalRequest._retry
    ) {
      console.log('Request failed due to old access token. Trying to refresh token...');
      originalRequest._retry = true;
      http.defaults.headers.common.Authorization = undefined;
      isRefreshing = true;
      return http
        .post('/accounts/token/refresh/', { refresh: localStorage.getItem('refresh') })
        .then((res) => {
          if (res.status === 201 || res.status === 200) {
            console.log('Refresh token succeeded!');
            isRefreshing = false;
            localStorage.setItem('token', res.data.access);
            localStorage.setItem('refresh', res.data.refresh);
            http.defaults.headers.common.Authorization = `Bearer ${res.data.access}`;
            http.defaults.headers.common['access-token'] = `Bearer ${res.data.access}`;
            originalRequest.headers.Authorization = `Bearer ${res.data.access}`;
            return http(originalRequest);
          }
          return Promise.reject(error);
        })
        .catch((newError) => {
          console.log('Refresh token failed. Trying again in 3 seconds...');
          isRefreshing = false;
          originalRequest._retry = false;
          setTimeout(() => {
            return catchWithRefresh(error).catch((e) => console.log(e));
          }, 3000);
        });
    }

    if (
      error &&
      error.response &&
      error.response.status === 401 &&
      error.request.responseURL.includes('refresh')
    ) {
      console.log('Refresh token failed due to token expiration. Redirecting to login...');
      isRefreshing = false;
      localStorage.removeItem('token');
      localStorage.removeItem('refresh');
      http.defaults.headers.common.Authorization = null;
      http.defaults.headers.common['access-token'] = null;
    }
    if (isRefreshing) {
      setTimeout(() => {
        originalRequest.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
        originalRequest.headers['access-token'] = `Bearer ${localStorage.getItem('token')}`;
        return http(originalRequest);
      }, 2000);
    }
    return Promise.reject(error);
  }, []);

  const clearUser = () => {
    setUser(undefined);
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
  };

  useEffect(() => {
    (async () => {
      if (user && user.profile && tycAccepted) {
        setIsFetching(true);
        try {
          const assets = await getUserAssets(user);
          const meters = await getUserMeters(user);
          setUserAssets(assets);
          setUserMeters(meters);
        } catch (e) {
          console.log(e);
        } finally {
          setIsFetching(false);
        }
      }
    })();
  }, [user, tycAccepted]);

  useEffect(() => {
    http.interceptors.response.handlers.forEach((handler, key) => {
      http.interceptors.response.eject(key);
    });

    http.interceptors.response.use((response) => response, catchWithRefresh);
  }, [catchWithRefresh]);

  useEffect(() => {
    const prevToken = localStorage.getItem('token');
    const prevRefresh = localStorage.getItem('refresh');
    if (prevToken) callFetchUser(prevToken, prevRefresh);
  }, [callFetchUser]);

  return (
    <UserSessionContext.Provider
      value={{
        user,
        setUser,
        tycAccepted,
        setTycAccepted,
        userAssets,
        userMeters,
        isFetching,
        isLoggingIn,
        clearUser,
        callFetchUser,
      }}
    >
      {children}
    </UserSessionContext.Provider>
  );
};

export default UserSessionProvider;
