import React, { useContext } from 'react';
import 'nprogress/nprogress.css';
import 'assets/vendors/style';
import { Route, Routes, useLocation } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import MainApp from 'app/index';
import AppLocale from 'util/lngProvider';
import SignIn from 'containers/SignInContainer';
import ResetPassword from 'containers/ResetPasswordContainer';
import ConfirmPassword from 'containers/ResetPasswordContainer/ConfirmPassword';
import { Navigate } from 'react-router-dom';
import SigninLayout from './SignInContainer/SigninLayout';
import SnackbarProvider from 'providers/SnackbarProvider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { esES, LocalizationProvider } from '@mui/x-date-pickers';
import { esES as coreEsES } from '@mui/material/locale';
import { esES as pickersEsES } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import defaultTheme from 'config/defaultTheme';
import { UserSessionContext } from 'providers/UserSessionProvider';
import CircularProgress from 'components/CircularProgress';
import RightPanelContainer from './RightPanelContainer';
import TosDialog from '../components/TosDialog';

const AppContainer = () => {
  const location = useLocation();
  const { user, tycAccepted, isLogginIn } = useContext(UserSessionContext);
  const applyTheme = createTheme(defaultTheme, esES, pickersEsES, coreEsES);
  const currentAppLocale = AppLocale.es;

  return (
    <ThemeProvider theme={applyTheme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <SnackbarProvider>
          <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
            {isLogginIn ? (
              <div className="d-flex align-items-center justify-content-center w-100 h-100">
                <CircularProgress />
              </div>
            ) : (
              <div className="app-main">
                <Routes location={location} key={location.pathname}>
                  <Route element={<SigninLayout />}>
                    <Route path="signin" element={<SignIn />} />
                    <Route path="reset-password" element={<ResetPassword />} />
                    <Route
                      path="reset-password-confirm/:token/:eula"
                      element={<ConfirmPassword />}
                    />
                    <Route path="*" element={<Navigate to="/signin" replace />} />
                  </Route>
                  {user && tycAccepted ? <Route path="app/*" element={<MainApp />} /> : null}
                </Routes>
                <RightPanelContainer />
                <TosDialog open={!!user && !tycAccepted} />
              </div>
            )}
          </IntlProvider>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default AppContainer;
