import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { http } from 'config/Api';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from 'components/CircularProgress';
import { SnackbarContext } from 'providers/SnackbarProvider';
import { UserSessionContext } from 'providers/UserSessionProvider';
import Nprogress from 'nprogress';
import { userSignIn } from 'services/users';

const SignIn = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { pushMessage } = useContext(SnackbarContext);
  const { user, tycAccepted, callFetchUser } = useContext(UserSessionContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && tycAccepted) navigate('/app/home', { replace: true });
  }, [navigate, user, tycAccepted]);

  const handleSubmitForm = (event) => {
    event.preventDefault();
    Nprogress.start();
    setIsLoading(true);
    const credentials = { username, password };
    http.defaults.headers.common.Authorization = undefined;
    userSignIn(credentials)
      .then((response) => {
        if (response.data.access) {
          const { access, refresh } = response.data;
          callFetchUser(access, refresh);
        } else {
          pushMessage('Usuario y/o contraseña incorrectos. Intente nuevamente.');
        }
        Nprogress.done();
        setIsLoading(false);
      })
      .catch((error) => {
        Nprogress.done();
        setIsLoading(false);
        if (error.response && error.response.data.password)
          return pushMessage('Debe ingresar una contraseña.');
        if (error.response && error.response.data.username)
          return pushMessage('Debe ingresar un usuario.');
        if (error.response && error.response.data.detail)
          return pushMessage('Usuario y/o contraseña incorrectos. Intente nuevamente.');
        return error;
      });
  };

  if (localStorage.getItem('token') && localStorage.getItem('refresh')) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <div className="app-login-content d-flex flex-column align-items-center">
          <div className="app-login-header">
            <h2 className="mb-4">
              <b>Ingreso de clientes</b>
            </h2>
          </div>
          <div className="app-login-form">
            <form onSubmit={handleSubmitForm}>
              <fieldset>
                <TextField
                  autoFocus
                  size="small"
                  variant="outlined"
                  label="Usuario"
                  fullWidth
                  onChange={(event) => setUsername(event.target.value)}
                  defaultValue={username}
                  margin="normal"
                  className="mt-1 my-sm-3"
                />
                <TextField
                  size="small"
                  variant="outlined"
                  type="password"
                  label={<IntlMessages id="appModule.password" />}
                  fullWidth
                  onChange={(event) => setPassword(event.target.value)}
                  defaultValue={password}
                  margin="normal"
                  className="mt-1 my-sm-3"
                />
                <div className="mb-3 d-flex align-items-center justify-content-center">
                  {!isLoading ? (
                    <Button
                      size="small"
                      type="submit"
                      className="w-100"
                      variant="contained"
                      color="primary"
                    >
                      <IntlMessages id="appModule.signIn" />
                    </Button>
                  ) : (
                    <CircularProgress />
                  )}
                </div>
                <div>
                  <Link to="/reset-password" title="Olvidé mi contraseña">
                    <span className="font-size-12">Olvidé mi contraseña</span>
                  </Link>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      <div className="logo-container">
        <Link className="logo-lg" to="/" title="Enerlink">
          <img src={require('assets/images/logo-h.png')} alt="Enerlink" title="Enerlink" />
        </Link>
      </div>
    </div>
  );
};

export default SignIn;
