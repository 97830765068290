import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import { FaBars } from 'react-icons/fa';
import IntlMessages from 'util/IntlMessages';
import { BiBell, BiSearch } from 'react-icons/bi';
import { openDrawer } from 'features/Common';

const Header = ({ openDrawer }) => {
  const location = useLocation();
  const subPath = location.pathname.replaceAll('/', '.').replaceAll('app.', '').replace('.', '');

  return (
    <div className="header-container d-flex justify-content-start">
      <IconButton className="d-block d-lg-none sidenav-button mr-3 p-0" onClick={openDrawer}>
        <FaBars />
      </IconButton>
      <div className="d-flex flex-column">
        <h1 className="header-title">
          <IntlMessages id={`pages.${subPath}`} />
        </h1>
        <h6 className="header-desc">
          <IntlMessages id={`pages.${subPath}.description`} />
        </h6>
      </div>
      <div className="d-flex align-items-start">
        <div className="d-flex align-items-center">
          <IconButton className="mr-3" variant="outlined" size="medium" aria-label="notifications">
            <BiBell />
          </IconButton>
          <div className="d-flex align-items-center search">
            <BiSearch className="mr-2" />
            <span>Buscar en la página...</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  openDrawer: () => dispatch(openDrawer()),
});

export default connect(null, mapDispatchToProps)(Header);
