import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from 'components/CircularProgress';
import { sendResetPasswordRequest } from 'services/users';
import { emailValidation } from 'constants/Regex';
import { UserSessionContext } from 'providers/UserSessionProvider';
import { SnackbarContext } from 'providers/SnackbarProvider';

const ResetPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [validCaptcha, setValidCaptcha] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resetRequestSent, setResetRequestSent] = useState(false);
  const { user } = useContext(UserSessionContext);
  const { pushMessage } = useContext(SnackbarContext);
  const emailError = !!email && !emailValidation.test(email);

  useEffect(() => {
    if (user) navigate('/app/home');
  }, [navigate, user]);

  const handleSubmitForm = (event) => {
    event.preventDefault();
    setIsLoading(true);
    sendResetPasswordRequest(email)
      .then((response) => {
        pushMessage('Email enviado exitosamente.');
        setResetRequestSent(true);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        if (e.response && e.response.data.email) pushMessage(e.response.data.email);
        setIsLoading(false);
      });
  };

  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <div className="app-login-content">
          <div className="mb-1">
            <Link to="/signin" title="Olvidé mi contraseña">
              <span className="font-size-12">Volver</span>
            </Link>
          </div>
          <div className="app-login-header">
            <h1 className="font-size-28">Recuperar contraseña</h1>
          </div>
          {!resetRequestSent ? (
            <>
              <div>
                <p className="m-0 font-size-14">
                  Un correo con instrucciones de recuperación le será enviado a la dirección que
                  ingrese a continuación.
                </p>
              </div>
              <div className="app-login-form">
                <form onSubmit={handleSubmitForm}>
                  <fieldset>
                    <TextField
                      size="small"
                      autoFocus
                      variant="outlined"
                      label="E-mail"
                      fullWidth
                      error={emailError}
                      helperText={emailError ? <span>Ingrese un email válido.</span> : null}
                      onChange={(event) => setEmail(event.target.value)}
                      defaultValue={email}
                      margin="normal"
                      className="mt-1 my-sm-3"
                    />
                    <div className="mb-3">
                      <ReCAPTCHA
                        sitekey="6Le8C-sZAAAAAFCwJR_UJRe34P4QHYRQGne7NGoQ"
                        onChange={() => setValidCaptcha(true)}
                      />
                    </div>
                    <div className="mb-3 d-flex align-items-center justify-content-center">
                      {!isLoading ? (
                        <Button
                          size="small"
                          type="submit"
                          className="w-100"
                          disabled={!email || !emailValidation.test(email) || !validCaptcha}
                          variant="contained"
                          color="primary"
                        >
                          Enviar link de recuperación
                        </Button>
                      ) : (
                        <CircularProgress />
                      )}
                    </div>
                  </fieldset>
                </form>
              </div>
            </>
          ) : (
            <div className="color-blue">
              <p>{`Un email de recuperación ha sido enviado a ${email}. Siga las instrucciones contenidas en el mismo para reestablecer su contraseña.`}</p>
            </div>
          )}
        </div>
      </div>
      <div className="logo-container">
        <Link className="logo-lg" to="/" title="Enerlink">
          <img src={require('assets/images/logo-h.png')} alt="Enerlink" title="Enerlink" />
        </Link>
      </div>
    </div>
  );
};

export default ResetPassword;
