import React from 'react';
import { Outlet } from 'react-router-dom';

const SigninLayout = () => {
  return (
    <div className="app-content">
      <div className="d-flex flex-column container-fluid width-auto p-md-4 p-3 flex-fill">
        <Outlet />
      </div>
    </div>
  );
};

export default SigninLayout;
