import React, { useContext, useState } from 'react';
import { UserSessionContext } from './../../providers/UserSessionProvider';
import { SnackbarContext } from './../../providers/SnackbarProvider';
import { updateProfile } from './../../services/users';
import moment from 'moment';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
} from '@mui/material';

type TosDialogProps = {
  open: boolean;
};

const TosDialog = ({ open }: TosDialogProps) => {
  const { user, setTycAccepted, clearUser } = useContext(UserSessionContext);
  const { pushMessage } = useContext(SnackbarContext);
  const [checkboxState, setCheckboxState] = useState(false);

  const handleAcceptTyc = (userId: number) => {
    const eula = moment().utc().format('YYYY-MM-DDTHH:mm:ss');
    updateProfile(userId, { eula })
      .then((response) => {
        setTycAccepted(eula);
        pushMessage('Usted ha aceptado las condiciones del servicio.');
      })
      .catch((e) => {
        console.log(e);
        pushMessage('Ha ocurrido un error. Inténtelo nuevamente');
      });
  };

  const handleClose = () => {
    setTycAccepted();
    setCheckboxState(false);
    clearUser();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">Términos del servicio</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Para continuar usando la plataforma, debes aceptar aceptar los términos y condiciones del
          servicio.
        </DialogContentText>
        <FormControlLabel
          control={
            <Checkbox
              checked={checkboxState}
              onChange={(event) => setCheckboxState(event.target.checked)}
              name="checkbox-tyc"
              color="secondary"
            />
          }
          label="He leído y acepto los términos y condiciones del servicio."
        />
      </DialogContent>
      <DialogActions>
        <div className="d-flex justify-content-between w-100 p-2">
          <a href="/ToS Enerlink.pdf" target="_blank">
            <Button onClick={handleClose}>Leer Términos del Servicio</Button>
          </a>
          <div>
            <Button
              onClick={() => handleAcceptTyc(user.id)}
              className="mr-2"
              autoFocus
              variant="contained"
              color="primary"
              disabled={!checkboxState}
            >
              Aceptar
            </Button>
            <Button onClick={handleClose} variant="contained" color="secondary">
              Cancelar
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};

TosDialog.defaultProps = {
  open: false,
};

export default TosDialog;
