import React, { useContext, useMemo } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BsFillLightningFill, BsFillGearFill, BsFillHouseDoorFill } from 'react-icons/bs';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import { NavLink, useLocation } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import { UserSessionContext } from 'providers/UserSessionProvider';
import { useDispatch } from 'react-redux';
import { closeDrawer } from 'features/Common';

const SidenavContent = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { user } = useContext(UserSessionContext);
  const pathBase = useMemo(() => `${pathname.split('/')[1]}`, [pathname]);

  const handleNavLinkOnClick = () => {
    setTimeout(() => {
      dispatch(closeDrawer());
    }, 100);
  };

  const adminViews = () => (
    <>
      <NavLink
        onClick={handleNavLinkOnClick}
        className="d-flex align-items-center nav-text"
        to={`/${pathBase}/home`}
      >
        <BsFillHouseDoorFill className="mr-3" />
        <IntlMessages id="pages.home" />
      </NavLink>
      <NavLink
        onClick={handleNavLinkOnClick}
        className="d-flex align-items-center nav-text"
        to={`/${pathBase}/consumptions`}
      >
        <BsFillLightningFill className="mr-3" />
        <IntlMessages id="pages.consumptions" />
      </NavLink>
      <Accordion defaultExpanded className={`${pathname.includes('/app/incomes') ? 'active' : ''}`}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="incomes-submenu"
          id="incomes-submenu"
          className={`d-flex align-items-center nav-text ${
            pathname.includes('/app/incomes') ? 'active' : ''
          }`}
        >
          <FaFileInvoiceDollar className="mr-3" />
          <IntlMessages id="pages.incomes" />
        </AccordionSummary>
        <AccordionDetails className="d-flex flex-column">
          <NavLink
            onClick={handleNavLinkOnClick}
            className="d-flex align-items-center nav-text"
            to={`/${pathBase}/incomes/byAsset`}
          >
            <IntlMessages id="pages.incomes.byAsset" />
          </NavLink>
          <NavLink
            onClick={handleNavLinkOnClick}
            className="d-flex align-items-center nav-text"
            to={`/${pathBase}/incomes/byMeter`}
          >
            <IntlMessages id="pages.incomes.byMeter" />
          </NavLink>
          <NavLink
            onClick={handleNavLinkOnClick}
            className="d-flex align-items-center nav-text"
            to={`/${pathBase}/incomes/incomeOnDemandByMeter`}
          >
            <IntlMessages id="pages.incomes.incomeOnDemandByMeter" />
          </NavLink>
        </AccordionDetails>
      </Accordion>
      <NavLink
        onClick={handleNavLinkOnClick}
        className="d-flex align-items-center nav-text"
        to={`/${pathBase}/administration`}
      >
        <BsFillGearFill className="mr-3" />
        <IntlMessages id="pages.administration" />
      </NavLink>
    </>
  );

  const userViews = () => (
    <>
      <NavLink
        onClick={handleNavLinkOnClick}
        className="d-flex align-items-center nav-text"
        to={`/${pathBase}/home`}
      >
        <BsFillHouseDoorFill className="mr-3" />
        <IntlMessages id="pages.home" />
      </NavLink>
      <NavLink
        onClick={handleNavLinkOnClick}
        className="d-flex align-items-center nav-text"
        to={`/${pathBase}/consumptions`}
      >
        <BsFillLightningFill className="mr-3" />
        <IntlMessages id="pages.consumptions" />
      </NavLink>
      <NavLink
        onClick={handleNavLinkOnClick}
        className="d-flex align-items-center nav-text"
        to={`/${pathBase}/incomes`}
      >
        <FaFileInvoiceDollar className="mr-3" />
        <IntlMessages id="pages.incomes" />
      </NavLink>
    </>
  );

  return (
    <ul className="nav-menu">
      <li className="menu no-arrow">{user && user.is_staff ? adminViews() : userViews()}</li>
    </ul>
  );
};

export default SidenavContent;
