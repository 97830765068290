import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ShapesSVG } from 'assets/images/sidebar-shapes.svg';
import UserInfo from 'components/UserInfo';
import SidenavContent from './SidenavContent';

const SideNavContainer = () => {
  return (
    <div className="app-sidebar">
      <div className="side-nav">
        <div className="logo">
          <Link className="app-logo" to="/">
            <img
              className="logo-img"
              src={require('assets/images/logo.png')}
              alt="logo"
              title="logo"
            />
          </Link>
        </div>
        <SidenavContent />
        <div className="logout">
          <UserInfo />
        </div>
        <ShapesSVG />
      </div>
    </div>
  );
};

export default SideNavContainer;
