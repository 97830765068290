import { http } from 'config/Api';
import { normalize } from 'normalizr';
import { meterSchema, meterListSchema } from 'features/schemas';
import Nprogress from 'nprogress';

// ----------------------------------- Actions ---------------------------------
// Action Types
const ADD_METER = 'meters/add_meter';
const LOAD_METERS = 'meters/load_meters';
const LOAD_METERS_BY_ASSET_NAME = 'meters/load_meters_by_asset_name';
const SET_USER_METERS = 'assets/set_user_meters';
const FETCH_START = 'meters/fetch_start';
const FETCH_END = 'meters/fetch_end';

// Action Creators
export const addMeter = (meter) => ({ type: ADD_METER, payload: meter });
export const loadMeters = (meters) => ({ type: LOAD_METERS, payload: meters });
export const loadMetersByAssetName = (meters, assetName) => ({
  type: LOAD_METERS_BY_ASSET_NAME,
  meta: { assetName },
  payload: meters,
});
export const setUserMeters = (metersIds) => ({ type: SET_USER_METERS, payload: metersIds });
export const fetchStart = () => ({ type: FETCH_START });
export const fetchEnd = () => ({ type: FETCH_END });

// Init State
const INIT_STATE = {
  userMetersIds: [],
  byId: {},
  fetching: false,
  byAssetName: {},
  allIds: [],
};

// ----------------------------------- Reducer ---------------------------------
// Reducer
const meterReducer = (state = INIT_STATE, action) => {
  const { type, meta, payload } = action;
  switch (type) {
    case ADD_METER: {
      return { ...state, byId: { ...state.byId, [payload.id]: payload } };
    }
    case LOAD_METERS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          ...payload,
        },
      };
    }
    case LOAD_METERS_BY_ASSET_NAME: {
      return {
        ...state,
        byAssetName: {
          ...state.byAssetName,
          [meta.assetName]: {
            ...state.byAssetName[meta.assetName],
            ...payload,
          },
        },
      };
    }
    case SET_USER_METERS: {
      return {
        ...state,
        userMetersIds: payload,
      };
    }
    case FETCH_START: {
      return { ...state, fetching: true };
    }
    case FETCH_END: {
      return { ...state, fetching: false };
    }
    default: {
      return state;
    }
  }
};

export default meterReducer;

export const fetchMeters = () => async (dispatch) => {
  const apiMeters = 'remarcacion/medidores/?page_size=999';
  dispatch(fetchStart());
  return http
    .get(apiMeters)
    .then((response) => {
      const normalizedData = normalize(response.data.results, meterListSchema);
      const { meters } = normalizedData.entities;
      if (meters) dispatch(loadMeters(meters));
      dispatch(fetchEnd());
      return response.data.results;
    })
    .catch((error) => {
      dispatch(fetchEnd());
      console.log(error);
      return error;
    });
};

export const fetchMeter = (meterId) => async (dispatch) => {
  const apiMeters = `remarcacion/medidores/${meterId}/`;
  return http
    .get(apiMeters)
    .then((response) => {
      const normalizedData = normalize(response.data, meterSchema);
      const { meters } = normalizedData.entities;
      if (meters) dispatch(loadMeters(meters));
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const fetchUserMeters = (metersIds) => async (dispatch) => {
  const promises = [];
  metersIds.forEach((meterId) => {
    const apiMeters = `remarcacion/medidores/${meterId}/`;
    const request = http.get(apiMeters);
    promises.push(request);
  });

  return Promise.all(promises)
    .then((responses) => {
      const metersArray = [];
      responses.forEach((response) => {
        metersArray.push(response.data);
      });
      const normalizedData = normalize(metersArray, meterListSchema);
      const { meters } = normalizedData.entities;
      if (meters) dispatch(loadMeters(meters));
      return metersArray;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const fetchMetersByAssetName = (assetName) => async (dispatch) => {
  const apiMeters = `remarcacion/medidores/por_activo/${assetName}/?page_size=999`;
  Nprogress.start();
  dispatch(fetchStart());
  return http
    .get(apiMeters)
    .then((response) => {
      const normalizedData = normalize(response.data.results, meterListSchema);
      const { meters } = normalizedData.entities;
      if (meters) dispatch(loadMetersByAssetName(meters, assetName));
      Nprogress.done();
      dispatch(fetchEnd());
      return response.data.results;
    })
    .catch((error) => {
      Nprogress.done();
      dispatch(fetchEnd());
      console.log(error);
      return error;
    });
};
