import { normalize } from 'normalizr';
import Nprogress from 'nprogress';
import { meterReadingListSchema } from 'features/schemas';
import { callNextPage } from 'util/callNextPage';

// ----------------------------------- Actions ---------------------------------
// Action Types
const LOAD_METER_READINGS_BY_MEASURER_ID = 'meter_readings/load_meter_readings_by_meter_id';
const CLEAR_METER_READINGS = 'meter_readings/clear_meter_readings';
const FETCH_START = 'meter_readings/fetch_start';
const FETCH_END = 'meter_readings/fetch_end';

// Action Creators
export const loadMeterReadingsByMeterId = (meterId, meterReadings, override) => ({
  type: LOAD_METER_READINGS_BY_MEASURER_ID,
  meta: { meterId, override },
  payload: meterReadings,
});

export const clearMeterReadings = () => ({ type: CLEAR_METER_READINGS });
export const fetchStart = () => ({ type: FETCH_START });
export const fetchEnd = () => ({ type: FETCH_END });

// Init State
const INIT_STATE = {
  fetching: false,
  byAssetId: {},
  byMeterId: {},
  allIds: [],
};

// ----------------------------------- Reducer ---------------------------------
// Reducer
const meterReadingReducer = (state = INIT_STATE, action) => {
  const { type, meta, payload } = action;
  switch (type) {
    case LOAD_METER_READINGS_BY_MEASURER_ID: {
      return {
        ...state,
        byMeterId: {
          ...state.byMeterId,
          [meta.meterId]: meta.override
            ? { ...payload }
            : { ...state.byMeterId[meta.meterId], ...payload },
        },
      };
    }
    case CLEAR_METER_READINGS: {
      return { ...state, byAssetId: {}, byMeterId: {} };
    }
    case FETCH_START: {
      return { ...state, fetching: true };
    }
    case FETCH_END: {
      return { ...state, fetching: false };
    }
    default: {
      return state;
    }
  }
};

export default meterReadingReducer;

export const fetchMeterReadingsByMeterId = (meterId, startDate, endDate) => async (dispatch) => {
  const apiMeterReadings = `remarcacion/lecturas/${meterId}/between/${startDate}/${endDate}/?page_size=999`;
  Nprogress.start();
  dispatch(fetchStart());
  const resolve = (data) => {
    const normalizedData = normalize(data, meterReadingListSchema);
    const { meterReadings } = normalizedData.entities;
    if (meterReadings) dispatch(loadMeterReadingsByMeterId(meterId, meterReadings));
    Nprogress.done();
    dispatch(fetchEnd());
    return data;
  };
  const reject = (error) => {
    Nprogress.done();
    dispatch(fetchEnd());
    console.log(error);
    return error;
  };
  return callNextPage(apiMeterReadings, resolve, reject);
};
