import React, { useState, useContext } from 'react';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { BiExit } from 'react-icons/bi';
import IntlMessages from 'util/IntlMessages';
import Nprogress from 'nprogress';
import { userSignOut } from 'services/users';
import { SnackbarContext } from 'providers/SnackbarProvider';
import { UserSessionContext } from 'providers/UserSessionProvider';
import { useNavigate } from 'react-router-dom';
import { closeDrawer } from 'features/Common';
import { useDispatch } from 'react-redux';

const UserInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const { pushMessage } = useContext(SnackbarContext);
  const { user, setUser } = useContext(UserSessionContext);

  const handleClick = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  const handleSignOut = () => {
    handleRequestClose();
    Nprogress.start();
    userSignOut(localStorage.getItem('refresh'))
      .then(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh');
        setUser(undefined);
        dispatch(closeDrawer());
        Nprogress.done();
        navigate('/signin', { replace: true });
      })
      .catch((e) => {
        if (e.response && e.response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('refresh');
        } else {
          pushMessage(e.message);
        }
        Nprogress.done();
      });
  };

  return (
    <div className="user-info-container d-flex flex-column align-items-center justify-content-center">
      <div
        role="button"
        tabIndex={0}
        className="d-flex align-items-center user-detail"
        onClick={handleClick}
      >
        <div className="avatar-background mr-2">
          <Avatar alt="..." src="https://via.placeholder.com/150x150" className="user-avatar " />
        </div>
        <div className="d-flex flex-column">
          <span className="user-name mb-1">{user.first_name || 'User'}</span>
          <span className="user-email subtitle">{user.email || 'email@example.com'}</span>
        </div>
      </div>
      <Menu
        className="user-info"
        id="simple-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleRequestClose}
        PaperProps={{
          style: {
            minWidth: 120,
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        <MenuItem onClick={handleSignOut}>
          <BiExit className="mr-2" />
          <IntlMessages id="popup.logout" />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserInfo;
