import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import MainApp from 'MainApp';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorkerRegistration from 'serviceWorkerRegistration';

Sentry.init({
  dsn: 'https://e4896a165567462194b3898d54ec7491@o555533.ingest.sentry.io/5695136',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.0,
});

const root = ReactDOM.createRoot(document.getElementById('app-site'));
root.render(
  <StrictMode>
    <BrowserRouter>
      {/* <UpdateNotification> */}
      <MainApp />
      {/* </UpdateNotification> */}
    </BrowserRouter>
  </StrictMode>
);

serviceWorkerRegistration.register();
