import React, { lazy, Suspense, useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import OnboardingProvider from 'providers/OnboardingProvider';
import { UserSessionContext } from 'providers/UserSessionProvider';
import CircularProgress from 'components/CircularProgress';
import SideNavbar from '../components/SideNavbar';
import Header from '../components/Header';
import SnackbarContainer from '../containers/SnackbarContainer';
import DialogContainer from '../containers/DialogContainer';

const AdminAdministration = lazy(() => import('app/routes/Administrator/Administration'));
const AdminConsumptions = lazy(() => import('app/routes/Administrator/Consumptions'));
const AdminHome = lazy(() => import('app/routes/Administrator/Home'));
const AdminIncomesByAsset = lazy(() => import('app/routes/Administrator/Incomes/ByAsset'));
const AdminIncomesByMeter = lazy(() => import('app/routes/Administrator/Incomes/ByMeter'));
const AdminIncomeOnDemandByMeter = lazy(() =>
  import('app/routes/Administrator/Incomes/IncomeOnDemandByMeter')
);
const UserHome = lazy(() => import('app/routes/User/Home'));
const UserConsumptions = lazy(() => import('app/routes/User/Consumptions'));
const UserIncomes = lazy(() => import('app/routes/User/Incomes'));
// const Error404 = lazy(() => import('components/Error404'));

const App = () => {
  const { user } = useContext(UserSessionContext);

  return (
    <>
      <SideNavbar />
      <div className="app-content">
        <div className="d-flex flex-column container-fluid width-auto p-md-4 p-3 flex-fill">
          <div className="mb-4">
            <Header />
          </div>
          <OnboardingProvider>
            <Suspense
              fallback={
                <div className="d-flex align-items-center justify-content-center w-100 h-100">
                  <CircularProgress />
                </div>
              }
            >
              <Routes>
                {user.is_staff ? (
                  <>
                    <Route index path="home" element={<AdminHome />} />
                    <Route path="consumptions" element={<AdminConsumptions />} />
                    <Route path="incomes/byAsset" element={<AdminIncomesByAsset />} />
                    <Route path="incomes/byMeter" element={<AdminIncomesByMeter />} />
                    <Route
                      path="incomes/incomeOnDemandByMeter"
                      element={<AdminIncomeOnDemandByMeter />}
                    />
                    <Route path="administration" element={<AdminAdministration />} />
                  </>
                ) : (
                  <>
                    <Route index path="home" element={<UserHome />} />
                    <Route path="consumptions" element={<UserConsumptions />} />
                    <Route path="incomes" element={<UserIncomes />} />
                  </>
                )}
                {/* <Route path="*" element={<Error404 />} /> */}
              </Routes>
            </Suspense>
            <SnackbarContainer />
            <DialogContainer />
          </OnboardingProvider>
        </div>
      </div>
    </>
  );
};

export default App;
