import appLocaleData from 'react-intl/locale-data/es';
import saMessages from '../locales/es_ES.json';

const saLang = {
  messages: {
    ...saMessages,
  },
  locale: 'es',
  data: appLocaleData,
};
export default saLang;
