import React, { useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/es.js';
import { isIOS, isMobile } from 'react-device-detect';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import UserSessionProvider from 'providers/UserSessionProvider';
import configureStore, { history } from './store';
import AppContainer from 'containers/AppContainer';
import getTagManagerContainerId from 'util/tagManager/tagManagerCode';

export const store = configureStore();
moment.locale('es'); // Set moment locale to spanish
const MainApp = () => {
  useEffect(() => {
    // Google Tag Manager
    const tagManagerCode = getTagManagerContainerId();
    if (!!tagManagerCode) {
      if (!!!document.getElementById('tagManagerHead')) {
        const tagManagerHead = document.createElement('script');
        tagManagerHead.id = 'tagManagerHead';
        tagManagerHead.innerHTML = `(function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l !== 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', '${tagManagerCode}');`;
        document.head.appendChild(tagManagerHead);
      }
      if (!!!document.getElementById('tagManagerBody')) {
        const tagManagerBody = document.createElement('noscript');
        tagManagerBody.id = 'tagManagerBody';
        const tagManagerIframe = document.createElement('iframe');
        tagManagerIframe.src = `https://www.googletagmanager.com/ns.html?id=${tagManagerCode}`;
        tagManagerIframe.height = '0';
        tagManagerIframe.width = '0';
        tagManagerIframe.style = 'display: none; visibility: hidden';
        tagManagerBody.appendChild(tagManagerIframe);
        document.body.appendChild(tagManagerBody);
      }
    }
    // set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height');
    } else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height');
    }
  }, []);

  return (
    <UserSessionProvider>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Routes>
            <Route path="/*" element={<AppContainer />} />
          </Routes>
        </ConnectedRouter>
      </Provider>
    </UserSessionProvider>
  );
};

export default MainApp;
