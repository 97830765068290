import { http } from 'config/Api';
import { normalize } from 'normalizr';
import Nprogress from 'nprogress';
import { assetSchema, assetListSchema } from 'features/schemas';
import { callNextPage } from 'util/callNextPage';

// ----------------------------------- Actions ---------------------------------
const LOAD_ASSETS = 'assets/load_assets';
const ADD_ASSET = 'assets/add_asset';
const SET_USER_ASSETS = 'assets/set_user_assets';

// Action Creators
export const addAsset = (asset) => ({ type: ADD_ASSET, payload: asset });
export const loadAssets = (assets) => ({ type: LOAD_ASSETS, payload: assets });
export const setUserAssets = (assetsNames) => ({ type: SET_USER_ASSETS, payload: assetsNames });

// Init State
const INIT_STATE = {
  userAssetsNames: [],
  byId: {},
  allIds: [],
};

// ----------------------------------- Reducer ---------------------------------
// Reducer
const assetReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_ASSET: {
      return { ...state, byId: { ...state.byId, [payload.id]: payload } };
    }
    case LOAD_ASSETS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          ...payload,
        },
      };
    }
    case SET_USER_ASSETS: {
      return {
        ...state,
        userAssetsNames: payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default assetReducer;

export const fetchAssets = () => async (dispatch) => {
  const apiAssets = 'organizacion/activos/';
  Nprogress.start();
  const resolve = (data) => {
    const normalizedData = normalize(data, assetListSchema);
    const { assets } = normalizedData.entities;
    if (assets) dispatch(loadAssets(assets));
    Nprogress.done();
    return data;
  };
  const reject = (error) => {
    Nprogress.done();
    console.log(error);
    return error;
  };
  return callNextPage(apiAssets, resolve, reject);
};

export const fetchAsset = (assetName) => async (dispatch) => {
  const apiAssets = `organizacion/activos/${assetName}/`;
  return http
    .get(apiAssets)
    .then((response) => {
      const normalizedData = normalize(response.data, assetSchema);
      const { assets } = normalizedData.entities;
      if (assets) dispatch(loadAssets(assets));
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const fetchUserAssets = (assetsNames) => async (dispatch) => {
  const promises = [];
  assetsNames.forEach((assetName) => {
    const apiAssets = `organizacion/activos/${assetName}/`;
    const request = http.get(apiAssets);
    promises.push(request);
  });

  return Promise.all(promises)
    .then((responses) => {
      const assetsArray = [];
      responses.forEach((response) => {
        assetsArray.push(response.data);
      });
      const normalizedData = normalize(assetsArray, assetListSchema);
      const { assets } = normalizedData.entities;
      if (assets) dispatch(loadAssets(assets));
      return assetsArray;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};
