import { http } from 'config/Api';

export const userSignIn = async (credentials) => http.post('accounts/token/pair/', credentials);
export const userSignOut = async (refresh) => http.post('accounts/token/logout/', { refresh });
export const fetchUser = async () => http.get('accounts/token/me/');

export const getUsers = async () => http.get('/accounts/user/?page_size=999');
export const postUser = async (user) => http.post('/accounts/user/', user);
export const updateUser = async (userId, user) => http.patch(`/accounts/user/${userId}/`, user);

export const updateProfile = async (userId, profile) =>
  http.patch(`/accounts/profile/${userId}/`, profile);

export const uploadUsersFile = (assetName, file) => {
  const formData = new FormData();
  formData.append('file_upload', file);
  formData.append('activo', assetName);
  return http.post('accounts/users-upload/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getUserAssets = (user) => {
  const promises = [];

  if (user && user.profile) {
    user.profile.activos.forEach((assetName) => {
      const apiAssets = `organizacion/activos/${assetName}/`;
      const request = http.get(apiAssets);
      promises.push(request);
    });

    return Promise.all(promises)
      .then((responses) => {
        const assetsArray = [];
        responses.forEach((response) => {
          assetsArray.push(response.data);
        });
        return assetsArray;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }
  return [];
};

export const getUserMeters = (user) => {
  const promises = [];

  if (user && user.profile) {
    user.profile.medidores.forEach((meterId) => {
      const apiMeters = `remarcacion/medidores/${meterId}/`;
      const request = http.get(apiMeters);
      promises.push(request);
    });

    return Promise.all(promises)
      .then((responses) => {
        const metersArray = [];
        responses.forEach((response) => {
          metersArray.push(response.data);
        });
        return metersArray;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }
  return [];
};

export const sendResetPasswordRequest = (email) => {
  http.defaults.headers.common.Authorization = undefined;
  return http.post('accounts/password_reset/', { email });
};

export const confirmPassword = (password, token) => {
  http.defaults.headers.common.Authorization = undefined;
  return http.post('accounts/password_reset/confirm/', { password, token });
};

export const validateToken = (token) => {
  http.defaults.headers.common.Authorization = undefined;
  return http.post('accounts/password_reset/validate_token/', { token });
};
