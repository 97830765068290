import React, { useState, createContext } from 'react';
import { Snackbar } from '@mui/material';

export const SnackbarContext = createContext();

const SnackbarProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);

  const pushMessage = (message) => {
    const newMessage = { text: message, open: true };
    setMessages((messages) => [...messages, newMessage]);
    setTimeout(() => {
      setMessages((messages) => {
        const newMessages = [...messages];
        newMessages[newMessages.indexOf(newMessage)].open = false;
        return newMessages;
      });
    }, 5000);
  };

  return (
    <SnackbarContext.Provider value={{ messages, pushMessage }}>
      {children}
      <div className="custom-snackbars-container">
        {messages.map((message, index) => (
          <Snackbar
            className="custom-snackbar"
            key={index}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={message.open}
            message={message.text}
          />
        ))}
      </div>
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;
