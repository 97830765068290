import React, { useState, useEffect, useCallback, createContext } from 'react';
import { Fab, Tooltip } from '@mui/material';
import { Steps } from 'intro.js-react';
import { BsQuestion } from 'react-icons/bs';
import 'intro.js/introjs.css';
import OnboardingSteps from './OnboardingSteps';

export const OnboardingContext = createContext();

const OnboardingProvider = ({ children }) => {
  const [stepsApi, setStepsApi] = useState();
  const [currentOnboarding, setCurrentOnboarding] = useState('home');
  const [steps, setSteps] = useState(OnboardingSteps[currentOnboarding]);
  const [enabled, setEnabled] = useState(false);
  const [onboardingVisited, setOnboardingVisited] = useState({
    home: localStorage.getItem('onboarding_home'),
    consumptions: localStorage.getItem('onboarding_consumptions'),
    incomes_by_asset: localStorage.getItem('onboarding_incomes_by_asset'),
    incomes_by_meter: localStorage.getItem('onboarding_incomes_by_meter'),
    income_on_demand_by_meter: localStorage.getItem('onboarding_income_on_demand_by_meter'),
    admin_users: localStorage.getItem('onboarding_admin_users'),
    admin_meters: localStorage.getItem('onboarding_admin_meters'),
    admin_proration: localStorage.getItem('onboarding_admin_proration'),
  });

  const startOnboarding = useCallback(() => {
    setSteps(OnboardingSteps[currentOnboarding]);
    setEnabled(true);
  }, [currentOnboarding]);

  useEffect(() => {
    if (!onboardingVisited[currentOnboarding]) {
      startOnboarding();
    }
  }, [currentOnboarding, onboardingVisited, startOnboarding]);

  const endOnboarding = useCallback(() => {
    setEnabled(false);
    setOnboardingVisited((onboardingVisited) => ({
      ...onboardingVisited,
      [currentOnboarding]: true,
    }));
    localStorage.setItem(`onboarding_${currentOnboarding}`, true);
  }, [currentOnboarding]);

  return (
    <OnboardingContext.Provider value={{ setCurrentOnboarding }}>
      {children}
      <Steps
        enabled={enabled}
        ref={(stepsApi) => setStepsApi(stepsApi)}
        onBeforeChange={(nextStepIndex) => {
          if (stepsApi && nextStepIndex && nextStepIndex < steps.length - 1)
            stepsApi.updateStepElement(nextStepIndex);
        }}
        initialStep={0}
        options={{
          skipLabel: 'SALTAR',
          nextLabel: 'SIGUIENTE',
          prevLabel: 'ATRÁS',
          doneLabel: '¡LISTO!',
          exitOnOverlayClick: false,
        }}
        steps={steps}
        onExit={endOnboarding}
      />
      <Tooltip title="Ayúdame a entender esta pantalla" placement="left">
        <div className="custom-onboarding-fab">
          <Fab color="secondary" aria-label="help" onClick={startOnboarding}>
            <BsQuestion size="28" />
          </Fab>
        </div>
      </Tooltip>
    </OnboardingContext.Provider>
  );
};

export default OnboardingProvider;
