import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer } from '@mui/material';
import { closeDrawer } from '../../features/Common';
import { drawerIsOpen } from '../../features/selectors';
import SideNavContainer from '../../containers/SideNavContainer';

const SideNavbar = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => drawerIsOpen(state));

  return (
    <>
      <Drawer className="d-fixed d-lg-none" open={isOpen} onClose={() => dispatch(closeDrawer())}>
        <SideNavContainer />
      </Drawer>
      <div className="d-none d-lg-flex">
        <SideNavContainer />
      </div>
    </>
  );
};

export default SideNavbar;
