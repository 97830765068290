import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { TextField, Button, FormControlLabel, Checkbox } from '@mui/material';
import CircularProgress from 'components/CircularProgress';
import { confirmPassword, validateToken } from 'services/users';
import { UserSessionContext } from 'providers/UserSessionProvider';
import { SnackbarContext } from 'providers/SnackbarProvider';

const ConfirmPassword = () => {
  const navigate = useNavigate();
  let { token, eula } = useParams();
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [checkboxState, setCheckboxState] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [tokenIsValid, setTokenIsValid] = useState(false);
  const [passwordConfirmed, setPasswordConfirmed] = useState(false);
  const { user, tycAccepted, setTycAccepted } = useContext(UserSessionContext);
  const { pushMessage } = useContext(SnackbarContext);

  useEffect(() => {
    if (user) navigate('/app/home');
  }, [navigate, user]);

  useEffect(() => {
    if (token) {
      setIsLoading(true);
      validateToken(token)
        .then((response) => {
          setTokenIsValid(true);
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setTokenIsValid(false);
          setIsLoading(false);
        });
    }
  }, [token]);

  useEffect(() => {
    if (eula !== 'None') {
      setTycAccepted(eula);
      setCheckboxState(true);
    }
  }, [eula, setTycAccepted]);

  const handleSubmitForm = (event) => {
    event.preventDefault();
    setIsLoading(true);

    confirmPassword(password, token)
      .then((response) => {
        pushMessage('Contraseña actualizada exitosamente');
        setPasswordConfirmed(true);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        if (e && e.response && e.response.data && e.response.data.password) {
          pushMessage(e.response.data.password[0]);
        } else {
          pushMessage('Error de conexión. Intente nuevamente.');
        }
        setIsLoading(false);
      });
  };

  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        {tokenIsValid ? (
          <>
            <div className="app-login-content">
              {passwordConfirmed ? (
                <>
                  <div>
                    <p>
                      Su contraseña ha sido actualizada exitosamente. Haga click en el siguiente
                      enlace para volver al login.
                    </p>
                  </div>
                  <Link to="/signin" title="Login">
                    <span className="font-size-12">Ir al login</span>
                  </Link>
                </>
              ) : (
                <>
                  <div className="mb-1">
                    <Link to="/signin" title="Login">
                      <span className="font-size-12">Ir al login</span>
                    </Link>
                  </div>
                  <div className="app-login-header">
                    <h1 className="font-size-28">Nueva contraseña</h1>
                  </div>
                  <div>
                    <p>
                      A continuación, ingrese una nueva contraseña para ingresar al
                      <b> Sistema de Medición Inteligente </b>
                      de Enerlink. Esta contraseña reemplazará a la anterior.
                    </p>
                  </div>
                  <div className="app-login-form">
                    <form onSubmit={handleSubmitForm}>
                      <fieldset>
                        <TextField
                          autoFocus
                          type="password"
                          variant="outlined"
                          label="Nueva contraseña"
                          fullWidth
                          required
                          onChange={(event) => setPassword(event.target.value)}
                          defaultValue={password}
                          margin="normal"
                          className="mt-1 my-sm-3"
                        />
                        <TextField
                          variant="outlined"
                          type="password"
                          label="Confirmar contraseña"
                          error={password !== password2}
                          fullWidth
                          required
                          onChange={(event) => setPassword2(event.target.value)}
                          defaultValue={password2}
                          margin="normal"
                          className="mt-1 my-sm-3"
                        />
                        {!tycAccepted ? (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={checkboxState}
                                onChange={(event) => setCheckboxState(event.target.checked)}
                                name="checkbox-tyc"
                                color="secondary"
                              />
                            }
                            label={
                              <span>
                                He leído y acepto los{' '}
                                <a href="/ToS Enerlink.pdf" target="_blank">
                                  términos y condiciones
                                </a>{' '}
                                del servicio
                              </span>
                            }
                          />
                        ) : null}
                        <div className="d-flex align-items-center justify-content-center mt-3">
                          {!isLoading ? (
                            <Button
                              size="small"
                              type="submit"
                              className="w-100"
                              variant="contained"
                              disabled={
                                !password || !password2 || password !== password2 || !checkboxState
                              }
                              color="primary"
                            >
                              Establecer nueva contraseña
                            </Button>
                          ) : (
                            <CircularProgress />
                          )}
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            {!isLoading ? (
              <span className="p-4">
                Link inválido o expirado. Intente repetir el proceso nuevamente.
              </span>
            ) : (
              <div className="p-5">
                <CircularProgress />
              </div>
            )}
          </>
        )}
      </div>
      <div className="logo-container">
        <Link className="logo-lg" to="/" title="Enerlink">
          <img src={require('assets/images/logo-h.png')} alt="Enerlink" title="Enerlink" />
        </Link>
      </div>
    </div>
  );
};

export default ConfirmPassword;
