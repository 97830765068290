import { http } from 'config/Api';
import { normalize } from 'normalizr';
import Nprogress from 'nprogress';
import FileDownload from 'js-file-download';
import { invoiceListSchema } from 'features/schemas';
import { showSnackbarMessage } from 'features/Common';
import { callNextPage } from 'util/callNextPage';

// ----------------------------------- Actions ---------------------------------
// Action Types
const LOAD_ASSETS_INVOICES = 'invoices/load_assets_invoices';
const LOAD_METERS_INVOICES = 'invoices/load_meters_invoices';
const LOAD_INVOICES_BY_ASSET_NAME = 'invoices/load_invoices_by_asset_name';
const LOAD_INVOICES_BY_METER_ID = 'invoices/load_invoices_by_meter_id';
const CLEAR_INVOICES = 'invoices/clear_invoices';
const FETCH_START = 'invoices/fetch_start';
const FETCH_END = 'invoices/fetch_end';

// Action Creators
export const loadAssetsInvoices = (invoices) => ({ type: LOAD_ASSETS_INVOICES, payload: invoices });
export const loadMetersInvoices = (invoices) => ({
  type: LOAD_METERS_INVOICES,
  payload: invoices,
});
export const loadInvoicesByAssetName = (assetName, invoices) => ({
  type: LOAD_INVOICES_BY_ASSET_NAME,
  meta: { assetName },
  payload: invoices,
});
export const loadInvoicesByMeterId = (meterId, invoices) => ({
  type: LOAD_INVOICES_BY_METER_ID,
  meta: { meterId },
  payload: invoices,
});
export const clearInvoices = () => ({ type: CLEAR_INVOICES });
export const fetchStart = () => ({ type: FETCH_START });
export const fetchEnd = () => ({ type: FETCH_END });

// Init State
const INIT_STATE = {
  fetching: false,
  byAssetName: {},
  byMeterId: {},
  assetsInvoices: {},
  metersInvoices: {},
  allIds: [],
};

// ----------------------------------- Reducer ---------------------------------
// Reducer
const invoiceReducer = (state = INIT_STATE, action) => {
  const { type, meta, payload } = action;
  switch (type) {
    case LOAD_ASSETS_INVOICES: {
      return {
        ...state,
        assetsInvoices: {
          ...state.assetsInvoices,
          ...payload,
        },
      };
    }
    case LOAD_METERS_INVOICES: {
      return {
        ...state,
        metersInvoices: {
          ...state.metersInvoices,
          ...payload,
        },
      };
    }
    case LOAD_INVOICES_BY_ASSET_NAME: {
      return {
        ...state,
        byAssetName: {
          ...state.byAssetName,
          [meta.assetName]: {
            ...state.byAssetName[meta.assetName],
            ...payload,
          },
        },
      };
    }
    case LOAD_INVOICES_BY_METER_ID: {
      return {
        ...state,
        byMeterId: {
          ...state.byMeterId,
          [meta.meterId]: {
            ...state.byMeterId[meta.meterId],
            ...payload,
          },
        },
      };
    }
    case CLEAR_INVOICES: {
      return { ...state, byAssetName: {}, byMeterId: {}, assetsInvoices: {}, metersInvoices: {} };
    }
    case FETCH_START: {
      return { ...state, fetching: true };
    }
    case FETCH_END: {
      return { ...state, fetching: false };
    }
    default: {
      return state;
    }
  }
};

export default invoiceReducer;

export const fetchInvoicesByAssetName = (assetName, startDate, endDate) => async (dispatch) => {
  const apiInvoices = `recaudacion/facturas-activos/${assetName}/between/${startDate}/${endDate}/?page_size=999`;
  Nprogress.start();
  dispatch(fetchStart());
  const resolve = (data) => {
    const normalizedData = normalize(data, invoiceListSchema);
    const { invoices } = normalizedData.entities;
    if (invoices) dispatch(loadInvoicesByAssetName(assetName, invoices));
    Nprogress.done();
    dispatch(fetchEnd());
    return data;
  };
  const reject = (error) => {
    Nprogress.done();
    dispatch(fetchEnd());
    console.log(error);
    return error;
  };
  return callNextPage(apiInvoices, resolve, reject);
};

export const fetchAllAssetsInvoices = (startDate, endDate) => async (dispatch) => {
  const apiInvoices = `recaudacion/notas-cobro-activo-periodo/${startDate}/${endDate}/`;
  Nprogress.start();
  dispatch(fetchStart());
  const resolve = (data) => {
    const normalizedData = normalize(data, invoiceListSchema);
    const { invoices } = normalizedData.entities;
    if (invoices) dispatch(loadAssetsInvoices(invoices));
    Nprogress.done();
    dispatch(fetchEnd());
    return data;
  };
  const reject = (error) => {
    Nprogress.done();
    dispatch(fetchEnd());
    console.log(error);
    return error;
  };
  callNextPage(apiInvoices, resolve, reject);
};

export const fetchInvoicesByMeterId = (meterId, startDate, endDate) => async (dispatch) => {
  const apiInvoices = `recaudacion/facturas-medidores/${meterId}/between/${startDate}/${endDate}/?page_size=999`;
  Nprogress.start();
  dispatch(fetchStart());
  const resolve = (data) => {
    const normalizedData = normalize(data, invoiceListSchema);
    const { invoices } = normalizedData.entities;
    if (invoices) dispatch(loadInvoicesByMeterId(meterId, invoices));
    Nprogress.done();
    dispatch(fetchEnd());
    return data;
  };
  const reject = (error) => {
    Nprogress.done();
    dispatch(fetchEnd());
    console.log(error);
    return error;
  };
  return callNextPage(apiInvoices, resolve, reject);
};

export const fetchAllMeterInvoicesByAsset = (assetName, startDate, endDate) => async (dispatch) => {
  const apiInvoices = `recaudacion/notas_de_cobro_medidor_por_activo_periodo/${assetName}/between/${startDate}/${endDate}/`;
  Nprogress.start();
  dispatch(fetchStart());
  const resolve = (data) => {
    const normalizedData = normalize(data, invoiceListSchema);
    const { invoices } = normalizedData.entities;
    if (invoices) dispatch(loadMetersInvoices(invoices));
    Nprogress.done();
    dispatch(fetchEnd());
    return data;
  };
  const reject = (error) => {
    Nprogress.done();
    dispatch(fetchEnd());
    console.log(error);
    return error;
  };
  return callNextPage(apiInvoices, resolve, reject);
};

export const downloadAssetSummary = (invoiceId, fileName) => async (dispatch) => {
  const apiInvoices = `recaudacion/facturas-activos/excel/${invoiceId}/`;
  Nprogress.start();
  return http
    .get(apiInvoices, { responseType: 'blob' })
    .then((response) => {
      Nprogress.done();
      FileDownload(response.data, fileName);
      return response.data;
    })
    .catch((error) => {
      Nprogress.done();
      dispatch(showSnackbarMessage('Hubo un error con la descarga.'));
      console.log(error);
      return error;
    });
};

export const downloadAssetDetail = (invoiceId, fileName) => async (dispatch) => {
  const apiInvoices = `recaudacion/facturas-activos/zip/${invoiceId}/`;
  Nprogress.start();
  return http
    .get(apiInvoices, { responseType: 'blob' })
    .then((response) => {
      Nprogress.done();
      FileDownload(response.data, fileName);
      return response.data;
    })
    .catch((error) => {
      Nprogress.done();
      dispatch(showSnackbarMessage('Hubo un error con la descarga.'));
      console.log(error);
      return error;
    });
};

export const downloadMeterSummary = (invoiceId, fileName) => async (dispatch) => {
  const apiInvoices = `recaudacion/facturas-medidores/download/${invoiceId}/`;
  Nprogress.start();
  return http
    .get(apiInvoices, { responseType: 'blob' })
    .then((response) => {
      Nprogress.done();
      FileDownload(response.data, fileName);
      return response.data;
    })
    .catch((error) => {
      Nprogress.done();
      console.log(error);
      return error;
    });
};
