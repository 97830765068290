import 'styles/app.scss';

// ------------------ From styles/global/_evx-variables.scss ---------------
const grayDarkest = '#19191c';
// const primaryDarkest = '#1a264a';
// const primaryDarker = '#26396f';
const primaryDark = '#334c94';
const primary = '#405fb9';
const primaryLight = '#667fc7';
// const primaryLighter = '#8c9fd5';
// const primaryLightest = '#eceff8';
// const secondaryDarkest = '#16454d';
// const secondaryDarker = '#226873';
const secondaryDark = '#2d8a9a';
const secondary = '#38adc0';
const secondaryLight = '#60bdcd';
// const secondaryLighter = '#88ced9';
// const secondaryLightest = '#afdee6';
const borderColor = '#ECECEC';
const white = '#FFFFFF';
// const black = '#333333';
const transparent = 'rgba(0, 0, 0, 0)';

// ------------------------------------ Buttons --------------------------------
const defaultTheme = {
  typography: {
    fontFamily: '"Varela Round", sans-serif',
    fontSize: 14,
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: 'inherit',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: '0px',
          marginRight: '0px',
          marginBottom: '0px',
        },
        label: {
          fontSize: '14px',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          padding: '4px',
          border: 'none',
          fontSize: '12px',
        },
        overlay: {
          backgroundColor: 'rgba(255, 255, 255, 0)',
        },
        cell: {
          borderBottom: '1px solid #302e35',
          padding: '16px 28px',
          lineHeight: '1.43 !important',
        },
        row: {
          backgroundColor: 'rgba(25, 25, 28, 0.4)',
          '&:hover': {
            backgroundColor: 'rgba(25, 25, 28, 0.2)',
          },
          // '&.Mui-selected': {
          //   backgroundColor: 'rgba(56, 173, 192, 0.2)',
          //   '&:hover': {
          //     backgroundColor: 'rgba(56, 173, 192, 0.4)',
          //   },
          // },
        },
        columnHeaders: {
          borderBottom: 'none',
        },
        columnHeader: {
          textTransform: 'uppercase',
          fontWeight: 'bold',
          fontSize: '10px',
          padding: '12px',
          color: borderColor,
          borderBottom: 'none',
          cursor: 'default',
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '&:focus-within': {
            outline: 'none',
          },
        },
        columnHeaderCheckbox: {
          padding: 0,
        },
        cellCheckbox: {
          padding: 0,
        },
        checkboxInput: {
          '&.Mui-checked': {
            color: primary,
          },
        },
        footerContainer: {
          borderTop: 'none',
          '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
            marginBottom: 0,
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          padding: '4px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          textTransform: 'uppercase',
          fontWeight: 'bold',
          fontSize: '10px',
          padding: '12px',
          paddingLeft: '28px',
          paddingRight: '28px',
          color: borderColor,
          borderBottom: 'none',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          border: 'none',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: grayDarkest,
          position: 'relative',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          '& ul': {
            paddingLeft: '12px',
            marginBottom: '0px',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          paddingRight: '0px !important',
        },
        input: {
          fontSize: '12px',
          padding: '9px',
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          alignItems: 'center',
          width: '28px',
          '.MuiButtonBase-root': {
            padding: 0,
            '.MuiSvgIcon-root': {
              width: '20px',
              height: '20px',
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: '#8d8d8d',
          fontSize: '12px',
        },
        outlined: {
          padding: '8px 16px',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          fontSize: '12px',
        },
        input: {
          padding: '0px 32px 0px 4px !important',
        },
        option: {
          fontSize: 12,
          '& .Mui-focused': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
          },
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
          },
        },
        paper: {
          backgroundColor: '#383838',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(255, 255, 255, 0.03)',
          textTransform: 'none',
        },
        textPrimary: {
          color: 'white',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.12)',
          },
          '&.Mui-disabled': {
            color: 'rgba(255, 255, 255, 0.3)',
            backgroundColor: 'rgba(255, 255, 255, 0.03)',
          },
        },
        contained: {
          background: primary,
          '&:hover': {
            backgroundColor: primaryDark,
          },
          '&.Mui-disabled': {
            color: 'rgba(255, 255, 255, 0.3)',
            backgroundColor: 'rgba(255, 255, 255, 0.12)',
          },
        },
        containedSecondary: {
          background: `white`,
          color: 'black',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.85)',
          },
        },
        outlined: {
          background: 'rgba(255, 255, 255, 0.03)',
          color: 'white',
          borderColor: 'rgba(255, 255, 255, 0.23)',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          '& .MuiButtonBase-root.MuiPickersDay-root': {
            backgroundColor: transparent,
          },
          '& .Mui-selected ': {
            backgroundColor: `${primary} !important`,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#202020',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundColor: '#232323',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          fontSize: 16,
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
          },
          '&.Mui-selected': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            '&:hover': {
              backgroundColor: `${'rgba(255, 255, 255, 0.1)'} !important`,
            },
          },
        },
        button: {
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {},
        paper: {
          maxHeight: '300px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 12,
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
          },
          '&.Mui-selected': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            '&:hover': {
              backgroundColor: `rgba(255, 255, 255, 0.1)`,
            },
          },
        },
      },
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      light: primaryLight,
      main: primary,
      dark: primaryDark,
      contrastText: white,
    },
    secondary: {
      light: secondaryLight,
      main: secondary,
      dark: secondaryDark,
      contrastText: white,
    },
  },
  status: {
    danger: 'orange',
  },
};

export default defaultTheme;
