import { http } from 'config/Api';

export const callNextPage = (api, resolve, reject, prevData) => {
  return http
    .get(api)
    .then((response) => {
      const dataKey = response.data.results || response.data.features;
      const data = prevData ? [...prevData, ...dataKey] : [...dataKey];
      if (response.data.next) return callNextPage(response.data.next, resolve, reject, data);
      return resolve(data);
    })
    .catch(reject);
};
