// import { createSelector } from 'reselect';

// Common
export const getSnackbarMessage = (state) => state.common.snackbarMessage;
export const getRightPanelContent = (state) => state.common.rightPanelContent;
export const rightPanelIsOpen = (state) => state.common.rightPanelIsOpen;
export const dialogIsOpen = (state) => state.common.dialogIsOpen;
export const getDialogContent = (state) => state.common.dialogContent;
export const drawerIsOpen = (state) => state.common.drawerOpen;

// Auth
export const getAuthUser = (state) => state.auth.authUser;
export const getInitUrl = (state) => state.auth.initURL;
export const getToken = (state) => state.auth.token;
export const isLogging = (state) => state.auth.logging;
export const isFetchingUser = (state) => state.auth.fetching;
export const resetRequestSent = (state) => state.auth.resetRequestSent;
export const tokenIsValid = (state) => state.auth.tokenIsValid;
export const passwordConfirmed = (state) => state.auth.passwordConfirmed;

// Users
export const getUsersList = (state) => state.users.byId;
export const getUserById = (state, id) => state.users.byId[id];
export const usersIsFetching = (state) => state.users.fetching;
export const usersIsUpdating = (state) => state.users.updating;

// Assets
export const getAssetsList = (state) => state.assets.byId;
export const getUserAssetsNames = (state) => state.assets.userAssetsNames;

// Meters
export const getMetersList = (state) => state.meters.byId;
export const getMetersListByAssetName = (state) => state.meters.byAssetName;
export const getUserMetersIds = (state) => state.meters.userMetersIds;
export const isMetersFetching = (state) => state.meters.fetching;

// Meter Readings
export const getMeterReadingsListedByMeterId = (state) => state.meterReadings.byMeterId;
export const isMeterReadingsFetching = (state) => state.meterReadings.fetching;

// Invoices
export const getAssetsInvoicesList = (state) => state.invoices.assetsInvoices;
export const getMetersInvoicesList = (state) => state.invoices.metersInvoices;
export const getInvoicesListByAssetName = (state) => state.invoices.byAssetName;
export const getInvoicesListByMeterId = (state) => state.invoices.byMeterId;
export const isInvoicesFetching = (state) => state.invoices.fetching;
