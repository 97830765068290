import React from 'react';
import { ReactComponent as HandshakeSVG } from 'assets/images/handshake.svg';
import { ReactComponent as HomeStepSVG } from 'assets/images/steps-home.svg';
import { ReactComponent as ConsumptionsStepSVG } from 'assets/images/steps-consumptions.svg';
import { ReactComponent as InvoicesStepSVG } from 'assets/images/steps-invoices.svg';
import { ReactComponent as AdmninUsersStep } from 'assets/images/steps-admin-users.svg';
import { ReactComponent as AdminMetersStep } from 'assets/images/steps-admin-meters.svg';
import logo from 'assets/images/logo-h.png';

const home = [
  {
    tooltipClass: 'steps-big',
    intro: (
      <div>
        <div className="step-tooltip-top">
          <img className="w-100" src={logo} alt="Enerlink" title="Enerlink" />
        </div>
        <div className="step-tooltip-bottom">
          <h3 className="text-center mb-3">
            Bienvenido al <br /> <b>Sistema de Medición Inteligente</b>
          </h3>
          <span>
            Haz click en <b>Siguiente</b> para empezar a <b>tomar el control</b> de tu energía.
          </span>
        </div>
      </div>
    ),
  },
  {
    tooltipClass: 'step-width-400 steps-big',
    intro: (
      <div>
        <div className="step-tooltip-top">
          <HomeStepSVG />
        </div>
        <div className="step-tooltip-bottom">
          <h4 className="mb-3">
            <b>Inicio</b>
          </h4>
          <span>
            Aquí puedes consultar de manera rápida el <b>estado</b> y los
            <b> consumos de las últimas 24 horas</b> de los medidores asociados a tus activos.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.filter-group-container',
    position: 'right',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Para empezar, selecciona un <b>activo</b> y un <b>medidor</b> para consultar los
            <b> consumos de las últimas 24 horas</b>.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.meters-status-container',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Acá se muestra un <b>resumen</b> de la <b>disponibilidad</b> de los medidores del activo
            seleccionado. Puedes hacer click en las barras del grafico resumen para desplegar el
            monitor de medidores con los medidores del estado seleccionado y también puedes
            seleccionar cada medidor.
            <ul>
              <li>
                <div className="d-flex align-items-center">
                  <div className="meter-square meter-online is-selected d-inline-block mt-0 mr-1" />
                  <b>En Linea</b>
                </div>
                medidor conectado y enviando datos de forma correcta.
              </li>
              <li>
                <div className="d-flex align-items-center">
                  <div className="meter-square meter-stop is-selected d-inline-block mt-0 mr-1" />
                  <b>En Corte</b>
                </div>
                medidor en corte programado.
              </li>
              <li>
                <div className="d-flex align-items-center">
                  <div className="meter-square meter-offline is-selected d-inline-block mt-0 mr-1" />
                  <b>Fuera de Línea</b>
                </div>
                medidor desconectado de la plataforma. Se requiere verificar en terreno.
              </li>
            </ul>
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.meter-chart-container',
    position: 'top',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Finalmente, si seleccionaste un medidor, se desplegará un gráfico con los
            <b> consumos de las últimas 24 horas</b> en ese medidor.
            <br />
            <br />
            Puedes hacer zoom a zonas específicas del gráfico para un análisis más detallado.
          </span>
        </div>
      </div>
    ),
  },
  {
    tooltipClass: 'steps-big',
    intro: (
      <div>
        <div className="step-tooltip-top">
          <HandshakeSVG />
        </div>
        <div className="step-tooltip-bottom">
          <span>
            ¡Ya aprendiste a utilizar el módulo de <b>Inicio</b>!
            <br />
            <br />
            Presiona el botón de ayuda en cualquier otro módulo para aprender más sobre ella.
          </span>
        </div>
      </div>
    ),
  },
];

const consumptions = [
  {
    tooltipClass: 'step-width-400 steps-big',
    intro: (
      <div>
        <div className="step-tooltip-top">
          <ConsumptionsStepSVG />
        </div>
        <div className="step-tooltip-bottom">
          <h4 className="mb-3">
            <b>Consumos</b>
          </h4>
          <span>
            En este módulo puedes realizar consultas detalladas de los{' '}
            <b>consumos de tus medidores</b> en el rango de fechas que tú determines. Además, podrás{' '}
            <b>descargar los datos</b> en formato excel.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.filter-bar',
    position: 'right',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Para empezar, selecciona un <b>activo</b>, un <b>medidor</b> y un <b>rango de fechas</b>
            .
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.search-button',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Ahora, presiona el botón <b>Buscar</b> para realizar la consulta.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.contained-section',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Si tu búsqueda generó resultados, el <b>gráfico de consumos</b> debería cargarse en esta
            sección. Si no es el caso, vuelve atrás e intenta con otro medidor o rango de fechas.
            <br />
            <br />
            El <b>gráfico de consumos</b> muestra los datos de consumos del medidor seleccionado
            dentro del rango de fechas que usted seleccionó.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.table-wrapper',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Adicionalmente, en esta sección puedes visualizar los datos de manera tabulada.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.download-button',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Finalmente, puedes <b>descargar</b> los datos fácilmente presionando el botón{' '}
            <b>Exportar a Excel</b>.
          </span>
        </div>
      </div>
    ),
  },
  {
    tooltipClass: 'steps-big',
    intro: (
      <div>
        <div className="step-tooltip-top">
          <HandshakeSVG />
        </div>
        <div className="step-tooltip-bottom">
          <span>
            ¡Ya aprendiste a utilizar el módulo de <b>Consumos</b>!
            <br />
            <br />
            Presiona el botón de ayuda en cualquier otro módulo para aprender más sobre ella.
          </span>
        </div>
      </div>
    ),
  },
];

const incomes_by_asset = [
  {
    tooltipClass: 'step-width-400 steps-big',
    intro: (
      <div>
        <div className="step-tooltip-top">
          <InvoicesStepSVG />
        </div>
        <div className="step-tooltip-bottom">
          <h4 className="mb-3">
            <b>Recaudaciones por activo</b>
          </h4>
          <span>
            Aquí podrás buscar las <b>notas de cobro</b> de todos los medidores de un mismo{' '}
            <b>activo</b> para un rango de fechas seleccionado. Por defecto, se muestran los
            registros de los <b>tres últimos meses</b> de todos los activos.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.filter-bar',
    position: 'right',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Primero, selecciona un <b>activo</b> y un <b>rango de fechas</b>.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.search-button',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Ahora, presiona el botón <b>Buscar</b> para realizar la consulta.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.consumptions-table-container',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Si la consulta generó resultados, en esta sección deberías ver una lista de{' '}
            <b>periodos de tarificación</b>. Si no es el caso, intenta una nueva búsqueda con otro
            activo o rango de fechas.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.periodHeader',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            La columna <b>Periodo</b> indica el <b>periodo de tarificación</b>.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.assetHeader',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            La columna <b>Activo</b> muestra el activo al que corresponde la tarificación.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.typeHeader',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            El <b>Tipo</b> indica si las nota de cobro se realizaron automáticamente según la fecha
            de tarificación <b>mensual</b>; o manualmente, dado un <b>egreso</b> a mitad de mes.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.totalHeader',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            La columna <b>Total</b> representa el <b>monto total</b> de todas las notas de cobro del
            activo seleccionado en un mismo periodo de tarificación.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.summaryHeader',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Puede descargar un excel de <b>resumen</b>, que detalla el <b>cobro calculado</b> del
            periodo para cada medidor del activo seleccionado.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.archiveHeader',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Además, puede descargar, en formato ZIP, el las <b>notas de cobro</b> de todos los
            medidores del activo para el periodo seleccionado.
            <br />
            <br />
            Estas puede compartirlas con sus arrendatarios manualmente, o <b>automatizarlas</b> a
            través del módulo de <b>Administración</b>. Para saber cómo, visite la sección de{' '}
            <b>Administración</b> y presione el <b>botón de ayuda</b>.
          </span>
        </div>
      </div>
    ),
  },
  {
    tooltipClass: 'steps-big',
    intro: (
      <div>
        <div className="step-tooltip-top">
          <HandshakeSVG />
        </div>
        <div className="step-tooltip-bottom">
          <span>
            ¡Ya aprendiste a utilizar el módulo de <b>Recaudaciones por activo</b>!
            <br />
            <br />
            Presiona el botón de ayuda en cualquier otro módulo para aprender más sobre ella.
          </span>
        </div>
      </div>
    ),
  },
];

const incomes_by_meter = [
  {
    tooltipClass: 'step-width-400 steps-big',
    intro: (
      <div>
        <div className="step-tooltip-top">
          <InvoicesStepSVG />
        </div>
        <div className="step-tooltip-bottom">
          <h4 className="mb-3">
            <b>Recaudaciones por medidor</b>
          </h4>
          <span>
            Aquí podrás buscar las <b>notas de cobro de un medidor</b> en los distintos periodos de
            un rango de fechas seleccionado.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.filter-bar',
    position: 'right',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Primero, selecciona un <b>activo</b>, un <b>medidor</b> y un <b>rango de fechas</b>.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.search-button',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Ahora, presiona el botón <b>Buscar</b> para realizar la consulta.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.consumptions-table-container',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Si la consulta generó resultados, en esta sección deberías ver una lista de{' '}
            <b>periodos de tarificación</b>. Si no es el caso, intenta una nueva búsqueda con otro
            activo o rango de fechas.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.periodHeader',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            La columna <b>Periodo</b> indica el <b>periodo de tarificación</b>.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.meterHeader',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            La columna <b>Medidor</b> muestra el medidor al que corresponde la tarificación.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.typeHeader',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            El <b>Tipo</b> indica si la nota de cobro se realizó automáticamente según la fecha de
            tarificación <b>mensual</b>; o manualmente, dado un <b>egreso</b> a mitad de mes.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.totalHeader',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          La columna <b>Total</b> representa el <b>monto total</b> de la nota de cobro del medidor
          en un periodo el tarificación.
          <span></span>
        </div>
      </div>
    ),
  },
  {
    element: '.archiveHeader',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Finalmente, es posible <b>descargar la nota de cobro</b> del periodo en formato PDF.
            <br />
            <br />
            Estas puede compartirlas con sus arrendatarios manualmente, o <b>automatizarlas</b> a
            través del módulo de <b>Administración</b>. Para saber cómo, visite la sección de{' '}
            <b>Administración</b> y presione el <b>botón de ayuda</b>.
          </span>
        </div>
      </div>
    ),
  },
  {
    tooltipClass: 'steps-big',
    intro: (
      <div>
        <div className="step-tooltip-top">
          <HandshakeSVG />
        </div>
        <div className="step-tooltip-bottom">
          <span>
            ¡Ya aprendiste a utilizar el módulo de <b>Recaudaciones por medidor</b>!
            <br />
            <br />
            Presiona el botón de ayuda en cualquier otro módulo para aprender más sobre ella.
          </span>
        </div>
      </div>
    ),
  },
];

const income_on_demand_by_meter = [
  {
    tooltipClass: 'step-width-400 steps-big',
    intro: (
      <div>
        <div className="step-tooltip-top">
          <InvoicesStepSVG />
        </div>
        <div className="step-tooltip-bottom">
          <h4 className="mb-3">
            <b>Tarificación por medidor</b>
          </h4>
          <span>
            Aqui podrás <b>tarificar por medidor</b> en un rango no mayor a un mes. La{' '}
            <b>nota de cobro</b> se enviara al correo de los administradores. Util para cuando se
            requiera una tarificacion especifica en caso de egreso de un arrendatario.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.filter-bar',
    position: 'right',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Primero, selecciona un <b>activo</b>, un <b>medidor</b> y un <b>rango de fechas</b>.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.pre-income-button',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Ahora, presiona el botón <b>Pre-Tarificar</b> para realizar una tarificación previa.
            Esta solo se hará para que puedas ver la información y decidir si confirmar la
            tarificación o no.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.tar-meter-container-info',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Si la consulta generó resultados, en esta sección deberías ver un{' '}
            <b>resumen del medidor, consumos y cargos</b> junto con un botón{' '}
            <b>Confirmar tarificación</b> para confirmar el proceso y <b>enviar la nota de cobro</b>{' '}
            al correo de todos los administradores. Si no es el caso, intenta una nueva búsqueda con
            otro activo o rango de fechas.
          </span>
        </div>
      </div>
    ),
  },
  /* {
    element: '.meter-consumption-info',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>Aquí podras ver la información de</span>
        </div>
      </div>
    ),
  },
  {
    element: '.meter-charges-info',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>b</span>
        </div>
      </div>
    ),
  },
  {
    element: '.confirm-tar-btn',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>c</span>
        </div>
      </div>
    ),
  }, */
  {
    tooltipClass: 'steps-big',
    intro: (
      <div>
        <div className="step-tooltip-top">
          <HandshakeSVG />
        </div>
        <div className="step-tooltip-bottom">
          <span>
            ¡Ya aprendiste a utilizar el módulo de <b>Tarificación por medidor</b>!
            <br />
            <br />
            Presiona el botón de ayuda en cualquier otro módulo para aprender más sobre ella.
          </span>
        </div>
      </div>
    ),
  },
];

const admin_users = [
  {
    tooltipClass: 'step-width-400 steps-big',
    intro: (
      <div>
        <div className="step-tooltip-top">
          <AdmninUsersStep />
        </div>
        <div className="step-tooltip-bottom">
          <h4 className="mb-3">
            <b>Administración de Arrendatarios</b>
          </h4>
          <span>
            Este módulo le permite:
            <ul>
              <li>
                <b>Registrar arrendatarios</b> a su edificio
              </li>
              <li>
                <b>Asociarlos</b> a uno o varios <b>medidores</b>
              </li>
              <li>
                Habilitar o deshabilitar su <b>ingreso a la plataforma</b>
              </li>
              <li>
                Automatizar el envío de <b>notas de cobro</b>
              </li>
            </ul>
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.select-element',
    position: 'right',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Aquí puedes seleccionar el <b>activo</b> del cual deseas gestionar arrendatarios.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.users-table',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Los arrendatarios registrados en el activo seleccionado se mostrarán en esta tabla.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.add-user-buttons',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Para añadir un nuevo usuario, puedes hacer click en <b>Añadir arrendatario</b>. También
            puedes <b>añadir múltiples arrendatarios</b> clickeando en la lengüeta derecha.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.emailHeader',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            La columna <b>Email</b> representa el email con el cual se registró al usuario.{' '}
            <b>Este debe ser único</b>.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.nameHeader',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            La columna <b>Nombre</b> muestra el nombre, apellido o razón social del arrendatario.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.meterHeader',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            La columna <b>Medidores Asociados</b> muestra los medidores por los cuales este
            arrendatario debe pagar en cada fecha de tarificación.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.accessHeader',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            También, puedes saber si el arrendatario tiene o no <b>acceso a la plataforma SMI</b>.
            En caso de tenerlo, será un <b>acceso limitado</b>, solo para lectura de sus propios
            consumos. Puedes definir esta opcion al momento de crear o editar un usuario.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.sendHeader',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Además, puedes saber si el arrendatario está suscrito al{' '}
            <b>envío de notas de cobro vía e-mail</b>. En caso de estarlo, se le enviará{' '}
            <b>automáticamente</b> mes a mes en la <b>fecha de tarificación</b> indicada. Puedes
            definir esta opcion al momento de crear o editar un usuario.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.actionsHeader',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Finalmente, puedes <b>actualizar los datos</b> y los <b>medidores asociados</b> de cada
            arrendatario a través de los botones de la columna <b>Acciones</b>.
          </span>
        </div>
      </div>
    ),
  },
  {
    tooltipClass: 'steps-big',
    intro: (
      <div>
        <div className="step-tooltip-top">
          <HandshakeSVG />
        </div>
        <div className="step-tooltip-bottom">
          <span>
            ¡Ya aprendiste a utilizar el módulo de <b>Administración de usuarios</b>!
            <br />
            <br />
            Presiona el botón de ayuda en cualquier otro módulo para aprender más sobre ella.
          </span>
        </div>
      </div>
    ),
  },
];

const admin_meters = [
  {
    tooltipClass: 'step-width-400 steps-big',
    intro: (
      <div>
        <div className="step-tooltip-top">
          <AdminMetersStep />
        </div>
        <div className="step-tooltip-bottom">
          <h4 className="mb-3">
            <b>Administración de Medidores</b>
          </h4>
          <span>
            Este módulo le permite:
            <ul>
              <li>
                Visualizar el <b>nombre y modelo</b> de los medidores de sus activos.
              </li>
              <li>
                Consultar y editar el <b>estado</b> de cada medidor.
              </li>
              <li>
                Configurar la <b>fecha de emisión</b> de cobros de cada medidor.
              </li>
            </ul>
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.select-element',
    position: 'right',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Aquí puedes seleccionar el <b>activo</b> del cual deseas gestionar arrendatarios.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.meters-table',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Luego, en esta tabla se mostrarán todos los <b>medidores</b> pertenecientes al{' '}
            <b>activo</b> seleccionado.
          </span>
        </div>
      </div>
    ),
  },
  {
    element:
      '.MuiDataGrid-columnHeader:nth-child(2) .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-columnHeaderTitle',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            La columna <b>Nombre</b> representa el alias asignado para el medidor
          </span>
        </div>
      </div>
    ),
  },
  {
    element:
      '.MuiDataGrid-columnHeader:nth-child(3) .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-columnHeaderTitle',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            La columna <b>Modelo</b> corresponde al modelo del medidor
          </span>
        </div>
      </div>
    ),
  },
  {
    element:
      '.MuiDataGrid-columnHeader:nth-child(4) .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-columnHeaderTitle',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            La columna <b>Próxima Emisión</b> indica la fecha en que se enviarán los cobros a la
            empresa proveedora de energía. Los cobros se consideran <b>hasta el día anterior</b> de
            la fecha de emisión indicada.
          </span>
        </div>
      </div>
    ),
  },
  {
    element:
      '.MuiDataGrid-columnHeader:nth-child(5) .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-columnHeaderTitle',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            El <b>Estado</b> representa la disponibilidad del medidor:
            <ul>
              <li>
                <div className="d-flex align-items-center">
                  <div className="meter-square meter-online is-selected d-inline-block mt-0 mr-1" />
                  <b>En Linea</b>
                </div>
                medidor conectado y enviando datos de forma correcta.
              </li>
              <li>
                <div className="d-flex align-items-center">
                  <div className="meter-square meter-stop is-selected d-inline-block mt-0 mr-1" />
                  <b>En Corte</b>
                </div>
                medidor en corte programado.
              </li>
              <li>
                <div className="d-flex align-items-center">
                  <div className="meter-square meter-offline is-selected d-inline-block mt-0 mr-1" />
                  <b>Fuera de Línea</b>
                </div>
                medidor desconectado de la plataforma. Se requiere verificar en terreno.
              </li>
            </ul>
          </span>
        </div>
      </div>
    ),
  },
  {
    element:
      '.MuiDataGrid-columnHeader:nth-child(6) .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-columnHeaderTitle',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            La columna <b>Acciones</b> le permite <b>editar individualmente</b> tanto el{' '}
            <b>estado</b> de un medidor como su <b>próxima fecha de emisión</b>.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.MuiDataGrid-columnHeader:nth-child(1)',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            También puede <b>seleccionar múltiples medidores</b> y aplicar acciones a todos ellos
            con los botones arriba de la tabla.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.action-bar',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Puedes editar el <b>estado</b> o editar la <b>próxima fecha de emisión</b> para todos
            los medidores seleccionados.
          </span>
        </div>
      </div>
    ),
  },
  {
    tooltipClass: 'steps-big',
    intro: (
      <div>
        <div className="step-tooltip-top">
          <HandshakeSVG />
        </div>
        <div className="step-tooltip-bottom">
          <span>
            ¡Ya aprendiste a utilizar el módulo de <b>Administración de medidores</b>!
            <br />
            <br />
            Presiona el botón de ayuda en cualquier otro módulo para aprender más sobre ella.
          </span>
        </div>
      </div>
    ),
  },
];

const admin_proration = [
  {
    tooltipClass: 'step-width-400 steps-big',
    intro: (
      <div>
        <div className="step-tooltip-top">
          <InvoicesStepSVG />
        </div>
        <div className="step-tooltip-bottom">
          <h4 className="mb-3">
            <b>Prorrateo</b>
          </h4>
          <span>
            En esta sección puede subir los cargos de los últimos cuatro meses, necesarios para
            hacer los <b>cálculos de prorrateo</b>.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.select-element',
    position: 'right',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Aquí puedes seleccionar el <b>activo</b> del cual deseas gestionar arrendatarios.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.proration-selection',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Luego, puedes seleccionar tanto la <b>fecha</b> como el <b>tipo de cargo</b> que quieres
            subir.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: 'form',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Si seleccionaste una fecha y un tipo de cargo, verás un <b>formulario</b> en esta
            sección.
          </span>
        </div>
      </div>
    ),
  },
  {
    element: '.MuiButton-containedPrimary',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Completalo con los datos que correspondan y luego presiona <b>Guardar</b>
          </span>
        </div>
      </div>
    ),
  },
  {
    tooltipClass: 'steps-big',
    intro: (
      <div>
        <div className="step-tooltip-bottom">
          <span>
            Si subiste los datos correctamente, al recargar esta página verás el formulario
            precargado con los datos que ingresaste. Recuerda repetir el proceso para los cargos de{' '}
            <b>Distribución, Generación e IGX</b>.
            <br />
            <br />
            La información que subas será considerada para el <b>próximo proceso de tarificación</b>
            , y se verá reflejada en las <b>notas de cobro</b> de tus arrendatarios.
          </span>
        </div>
      </div>
    ),
  },
  {
    tooltipClass: 'steps-big',
    intro: (
      <div>
        <div className="step-tooltip-top">
          <HandshakeSVG />
        </div>
        <div className="step-tooltip-bottom">
          <span>
            ¡Ya aprendiste a utilizar el módulo de <b>Prorrateo</b>!
            <br />
            <br />
            Presiona el botón de ayuda en cualquier otro módulo para aprender más sobre ella.
          </span>
        </div>
      </div>
    ),
  },
];

const OnboardingSteps = {
  home,
  consumptions,
  incomes_by_asset,
  incomes_by_meter,
  income_on_demand_by_meter,
  admin_users,
  admin_meters,
  admin_proration,
};

export default OnboardingSteps;
