import { schema } from 'normalizr';

/* Schemas: Here you can stablish the relations between each entity on the redux store. This is used to normalize api responses */

// Users
export const userSchema = new schema.Entity('users');
export const userListSchema = new schema.Array(userSchema);

// Assets
export const assetSchema = new schema.Entity('assets', {}, { idAttribute: 'nombre' });
export const assetListSchema = new schema.Array(assetSchema);

// Meters
export const meterSchema = new schema.Entity('meters');
export const meterListSchema = new schema.Array(meterSchema);

// Meters
export const meterReadingSchema = new schema.Entity('meterReadings');
export const meterReadingListSchema = new schema.Array(meterReadingSchema);

// Invoices
export const invoiceSchema = new schema.Entity('invoices');
export const invoiceListSchema = new schema.Array(invoiceSchema);
