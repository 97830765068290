const EUROCORP_TAG = 'GTM-WZXZ55F';
const CBRE_TAG = 'GTM-54LFT5B';
const LARGROUP_TAG = 'GTM-PNNDVVK';

const getTagManagerContainerId = () => {
  switch (window.location.host) {
    case 'eurocorp.enerlink.cl':
      return EUROCORP_TAG;
    case 'largroup.enerlink.cl':
      return LARGROUP_TAG;
    case 'cbre.enerlink.cl':
      return CBRE_TAG;
    default:
      return undefined;
  }
};
export default getTagManagerContainerId;
