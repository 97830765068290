import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import commonReducer from 'features/Common';
import assetReducer from 'features/Assets';
import invoiceReducer from 'features/Invoices';
import meterReducer from 'features/Meters';
import meterReadingReducer from 'features/MeterReadings';
import userReducer from 'features/Users';

const reducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    common: commonReducer,
    assets: assetReducer,
    invoices: invoiceReducer,
    meters: meterReducer,
    meterReadings: meterReadingReducer,
    users: userReducer,
  });

export default reducer;
