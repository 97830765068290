import { http } from 'config/Api';
import { normalize } from 'normalizr';
import Nprogress from 'nprogress';
import { showSnackbarMessage } from 'features/Common';
import { userSchema, userListSchema } from 'features/schemas';
import { callNextPage } from 'util/callNextPage';
import _ from 'lodash';

// ----------------------------------- Actions ---------------------------------
// Action Types
const LOAD_USERS = 'users/load_users';
const LOAD_STAYS = 'users/load_stays';
const LOAD_USER_PROFILE = 'users/load_user_profile';
const PATCH_STAY = 'users/patch_stay';
const DELETE_STAY = 'users/delete_stay';
const CLEAR_USERS = 'users/clear_users';
const FETCH_START = 'users/fetch_start';
const FETCH_END = 'users/fetch_end';
const UPDATE_START = 'users/update_start';
const UPDATE_END = 'users/update_end';
export const USER_SET_PROFILE_START = 'auth/user_set_profile_start';
export const USER_SET_PROFILE_END = 'auth/user_set_profile_end';

// Action Creators
export const loadUsers = (users) => ({ type: LOAD_USERS, payload: users });
export const loadStays = (userId, stays) => ({
  type: LOAD_STAYS,
  meta: { userId },
  payload: stays,
});
export const patchStay = (userId, stay) => ({
  type: PATCH_STAY,
  meta: { userId },
  payload: stay,
});
export const deleteStay = (userId, stayId) => ({
  type: DELETE_STAY,
  meta: { userId, stayId },
});
export const loadUserProfile = (userId, profile) => ({
  type: LOAD_USER_PROFILE,
  meta: { userId },
  payload: profile,
});
export const clearUsers = () => ({ type: CLEAR_USERS });
export const fetchStart = () => ({ type: FETCH_START });
export const fetchEnd = () => ({ type: FETCH_END });
export const userSetProfileStart = () => ({ type: USER_SET_PROFILE_START });
export const userSetProfileEnd = () => ({ type: USER_SET_PROFILE_END });
export const updateStart = () => ({ type: UPDATE_START });
export const updateEnd = () => ({ type: UPDATE_END });

// Init State
const INIT_STATE = {
  byId: {},
  fetching: false,
  updating: false,
  allIds: [],
};

// ----------------------------------- Reducer ---------------------------------
// Reducer
const userReducer = (state = INIT_STATE, action) => {
  const { type, meta, payload } = action;
  switch (type) {
    case LOAD_USERS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          ...payload,
        },
      };
    }
    case LOAD_STAYS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [meta.userId]: {
            ...state.byId[meta.userId],
            profile: {
              ...state.byId[meta.userId].profile,
              userstay: [...state.byId[meta.userId].profile.userstay, ...payload],
            },
          },
        },
      };
    }
    case PATCH_STAY: {
      const currentUserStays = _.cloneDeep(state.byId[meta.userId].profile.userstay);
      const stayIndex = _.findIndex(currentUserStays, (stay) => stay.id === payload.id);
      currentUserStays[stayIndex] = payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [meta.userId]: {
            ...state.byId[meta.userId],
            profile: {
              ...state.byId[meta.userId].profile,
              userstay: [...currentUserStays],
            },
          },
        },
      };
    }
    case DELETE_STAY: {
      const currentUserStays = _.cloneDeep(state.byId[meta.userId].profile.userstay);
      const stayIndex = _.findIndex(currentUserStays, (stay) => stay.id === meta.stayId);
      currentUserStays.splice(stayIndex, 1);
      return {
        ...state,
        byId: {
          ...state.byId,
          [meta.userId]: {
            ...state.byId[meta.userId],
            profile: {
              ...state.byId[meta.userId].profile,
              userstay: [...currentUserStays],
            },
          },
        },
      };
    }
    case LOAD_USER_PROFILE: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [meta.userId]: {
            ...state.byId[meta.userId],
            profile: {
              ...state.byId[meta.userId].profile,
              ...payload,
            },
          },
        },
      };
    }
    case FETCH_START: {
      return { ...state, fetching: true };
    }
    case FETCH_END: {
      return { ...state, fetching: false };
    }
    case UPDATE_START: {
      return { ...state, updating: true };
    }
    case UPDATE_END: {
      return { ...state, updating: false };
    }
    case CLEAR_USERS: {
      return { ...state, byId: {} };
    }
    default: {
      return state;
    }
  }
};

export default userReducer;

export const fetchUsers = () => async (dispatch) => {
  const apiUsers = 'accounts/user/';
  dispatch(clearUsers());
  Nprogress.start();
  dispatch(fetchStart());
  const resolve = (data) => {
    const normalizedData = normalize(data, userListSchema);
    const { users } = normalizedData.entities;
    if (users) dispatch(loadUsers(users));
    Nprogress.done();
    dispatch(fetchEnd());
    return data;
  };
  const reject = (error) => {
    Nprogress.done();
    dispatch(fetchEnd());
    console.log(error);
    return error;
  };
  return callNextPage(apiUsers, resolve, reject);
};

export const fetchUsersByAssetName = (assetName) => async (dispatch) => {
  const apiUsers = `accounts/user/by_asset/${assetName}/?page_size=1000`;
  dispatch(clearUsers());
  Nprogress.start();
  dispatch(fetchStart());
  const resolve = (data) => {
    const normalizedData = normalize(data, userListSchema);
    const { users } = normalizedData.entities;
    if (users) dispatch(loadUsers(users));
    Nprogress.done();
    dispatch(fetchEnd());
    return data;
  };
  const reject = (error) => {
    Nprogress.done();
    dispatch(fetchEnd());
    console.log(error);
    return error;
  };
  return callNextPage(apiUsers, resolve, reject);
};

export const userSetProfile = (userId, user, profile, message) => (dispatch) => {
  const apiProfile = `accounts/profile/${userId}/`;
  return http
    .patch(apiProfile, profile)
    .then((response) => {
      const profile = response.data;
      dispatch(loadUserProfile(user.id, profile));
      dispatch(showSnackbarMessage(message));
      dispatch(updateEnd());
      return response.data;
    })
    .catch((error) => {
      dispatch(showSnackbarMessage('No se ha podido completar la acción.'));
      dispatch(updateEnd());
      console.log(error);
      Nprogress.done();
      return error;
    });
};

export const updateUser = (userId, user, profile) => async (dispatch) => {
  const apiUsers = `accounts/user/${userId}/`;
  dispatch(updateStart());
  return http
    .patch(apiUsers, user)
    .then((response) => {
      const normalizedData = normalize(response.data, userSchema);
      const { users } = normalizedData.entities;
      if (users) {
        dispatch(loadUsers(users));
        dispatch(
          userSetProfile(userId, response.data, profile, 'Arrendatario actualizado exitosamente.')
        );
      }
      return response.data.results;
    })
    .catch((error) => {
      dispatch(showSnackbarMessage('No se ha podido actualizar el arrendatario.'));
      dispatch(updateEnd());
      console.log(error);
      return error;
    });
};

export const addUser = (user, profile) => async (dispatch) => {
  const apiUsers = 'accounts/user/';
  dispatch(updateStart());
  return http
    .post(apiUsers, user)
    .then((response) => {
      const normalizedData = normalize(response.data, userSchema);
      const { users } = normalizedData.entities;
      if (users) {
        dispatch(loadUsers(users));
        dispatch(
          userSetProfile(
            response.data.id,
            response.data,
            profile,
            'Arrendatario añadido exitosamente.'
          )
        );
      }
      return response.data;
    })
    .catch((error) => {
      dispatch(updateEnd());
      const { data } = error.response;
      console.log(error.response);
      if (data.username) {
        dispatch(showSnackbarMessage(data.username));
        return Promise.reject(data.username);
      }
      dispatch(showSnackbarMessage('No se ha podido actualizar el arrendatario.'));
      return data;
    });
};

export const updateStay = (stay) => async (dispatch) => {
  const apiStay = `accounts/user-stay/${stay.id}/`;
  dispatch(updateStart());
  return http
    .patch(apiStay, stay)
    .then((response) => {
      if (response.data) {
        dispatch(patchStay(response.data.user.user, response.data));
      }
      dispatch(showSnackbarMessage('Asociación actualizada exitosamente.'));
      dispatch(updateEnd());
      return response.data;
    })
    .catch((error) => {
      dispatch(showSnackbarMessage('No se ha podido actualizar la asociación.'));
      dispatch(updateEnd());
      console.log(error);
      return error;
    });
};

export const addStay = (stay) => async (dispatch) => {
  const apiStay = 'accounts/user-stay/';
  dispatch(updateStart());
  return http
    .post(apiStay, stay)
    .then((response) => {
      if (response.data) {
        dispatch(loadStays(response.data.user.user, [response.data]));
      }
      dispatch(showSnackbarMessage('Asociación realizada exitosamente.'));
      dispatch(updateEnd());
      return response.data;
    })
    .catch((error) => {
      dispatch(showSnackbarMessage('No se ha podido realizar la asociación.'));
      dispatch(updateEnd());
      console.log(error);
      return error;
    });
};

export const removeStay = (userId, stayId) => async (dispatch) => {
  const apiStay = `accounts/user-stay/${stayId}/`;
  dispatch(updateStart());
  return http
    .delete(apiStay)
    .then((response) => {
      dispatch(deleteStay(userId, stayId));
      dispatch(showSnackbarMessage('Asociación eliminada exitosamente.'));
      dispatch(updateEnd());
      return response.data;
    })
    .catch((error) => {
      dispatch(showSnackbarMessage('No se ha podido eliminar la asociación.'));
      dispatch(updateEnd());
      console.log(error);
      return error;
    });
};
