import axios from 'axios';

const baseURL = window.location.origin; // https://smi.enerlink.cl/
const headers = { 'Content-Type': 'application/json' };
const xsrfHeaderName = 'X-CSRFTOKEN';
const xsrfCookieName = 'csrftoken';
const withCredentials = true;

export const http = axios.create({
  baseURL,
  headers,
  xsrfHeaderName,
  xsrfCookieName,
  withCredentials,
});
